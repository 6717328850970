import { cloneDeep } from 'lodash-es'
import api from '@/Api'
import mixins from '../../romaCommon.js'

const getDefaultState = () => {
  return {
    ledgerData: null,

    ledgerTransferDetail: null,

    startDateSelected: null,
    endDateSelected: null,

    ledgerId: null,

    error: null,
    ledgerTransferError: null,
  }
}

const state = getDefaultState();

const mutations = {
  setLedgerData(state, values) {
    state.ledgerData = values
  },
  setLedgerTransferDetail(state, value) {
    state.ledgerTransferDetail = value
  },
  setStartDateSelected(state, startDateSelected) {
    state.startDateSelected = startDateSelected
  },
  setEndDateSelected(state, endDateSelected) {
    state.endDateSelected = endDateSelected
  },
  setLedgerId(state, ledgerId) {
    state.ledgerId = ledgerId
  },
  setError(state,value) {
    state.error = value
  },
  setLedgerTransferError(state,value) {
    state.ledgerTransferError = value
  },
  clearError(state) {
    state.error = null
    state.ledgerTransferError = null
  }
}

const getters = {
  ledgerData: state => {
    return state.ledgerData
  },
  ledgerTransferDetail: state => {
    return state.ledgerTransferDetail
  },
  transactions: state => {
    if (state.ledgerData == null) return { result: [], count: 0 }

    var balanceForward = {
      balance: state.ledgerData.balanceForward,
      debit: {amount: 0, formatted: "0.00"},
      credit: {amount: 0, formatted: "0.00"},
      date: state.ledgerData.fromDate,
      currencyCode: state.ledgerData.currencyCode,
      description: '',
      ledgerTransactionId: null,
      reference: null,
      transactionType: -1,
    }

    const transCopy = cloneDeep(state.ledgerData.transactions)
    return {
      result: [...transCopy, balanceForward],
      count: transCopy.length + 1
    }
  },
  error: state => {
    return state.error
  }
}

const actions = {
  loadLedger({ commit, state }) {
    commit('setLedgerData', null)
    commit('clearError')
    return api.getLedgerTransactions(state.ledgerId, {
      fromDate: mixins.formatJsDate_localTZ(state.startDateSelected), 
      toDate: mixins.formatJsDate_localTZ(state.endDateSelected), 
    }).then( (response) => {
        console.log("ledger loaded: ", response.data);
        commit('setLedgerData', response.data)
        return response
      }).catch(error => {
      console.error("failed to load ledger:", error)
      commit('setError', { 
        message: "Failed to load ledger",
        reason: mixins.formatApiErrorMessage(error)
      })
    })
  },
  updateLedger({ commit, dispatch }, ledger) {
    return api.updateLedger(state.ledgerData.ledgerId, ledger)
      .then( (response) =>  {
        console.log("update ledger success: ", response.data)
        dispatch("loadLedger")
        return response
      }).catch(error => {
        console.error("failed to update ledger:", error)
        commit('setError', { 
          message: "Failed to update ledger",
          reason: mixins.formatApiErrorMessage(error)
        })
      })
  },
  loadTransferDetail({ commit }, transactionId) {
    commit('setLedgerTransferDetail', null)
    commit('clearError')
    api.getLedgerTransfer(transactionId)
      .then( (response) => {
        console.log("ledger transfer loaded: ", response.data);
        commit('setLedgerTransferDetail', response.data)
      }).catch(error => {
      console.error("failed to load ledger transfer:", error)
      commit('setLedgerTransferError', { 
        message: "Failed to load ledger transfer details",
        reason: mixins.formatApiErrorMessage(error)
      })
    })
  },
  deleteTransaction( {commit}, transId) {
    return api.deleteTransaction(transId)
      .then( response => {
        console.log('ledgerTransaction deleted: ', response)
        return response
      })
      .catch(error => {
        console.log('failed to delete ledger transaction: ', error)
        commit('setError', {
          message: "Failed to delete ledger transaction: ",
          reason: mixins.formatApiErrorMessage(error)
        })
      })
  },
  updateTransaction( {commit}, payload) {
    return api.updateTransaction(payload.transId, payload.params)
      .then( response => {
        console.log('ledgerTransaction updated: ', response)
        return response
      })
      .catch(error => {
        console.log('failed to update ledger transaction: ', error)
        commit('setError', {
          message: "Failed to update ledger transaction: ",
          reason: mixins.formatApiErrorMessage(error)
        })
      })
  },
  setSelectedDateRange({ commit, dispatch }, dateRange) {
    commit('setStartDateSelected', dateRange.startDate)
    commit('setEndDateSelected', dateRange.endDate)
    dispatch('loadLedger')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}