import { cloneDeep } from 'lodash-es'
import api from '@/Api'
import mixins from '../../romaCommon.js'

const state = () => ({
  fromDate: null,
  toDate:  null,
  selectedDateRange: null,
  searchTerm: null, 
  ledgerTransactionData: { result: [], count: 0 },
  activeTransTypes: [],
})

const mutations = {
  setError(state, error) {
    state.error = error
  },
  clearError(state) {
    state.error = null
  },
  setLedgerTransactionData(state, value) {
    state.ledgerTransactionData = {
      result: value.rows, 
      count: value.rows.length
    }
  },
  setSelectedDateRange(state, selectedDateRange) {
    state.selectedDateRange = selectedDateRange
  },
  setActiveTransTypes(state, types) {
    state.activeTransTypes = types
  },
  setSearchTerm(state, term) {
    state.searchTerm = term
  }
}

const getters = {
  ledgerTransactions: state => {
    return cloneDeep(state.ledgerTransactionData)
  },
  selectedDateRange: state => {
    return state.selectedDateRange
  },
  activeTransTypes: state => {
    return state.activeTransTypes
  }, 
  searchTerm: state => {
    return state.searchTerm
  },
  error: state => {
    return state.error
  }
}

const actions = {
  loadTransactions({ commit,state }) {

    commit('setError', null) 

      const fromDate = state.selectedDateRange[0]
      const toDate = state.selectedDateRange[1]
      var params = new URLSearchParams( {
        fromDate: mixins.formatJsDate_localTZ(fromDate),
        toDate: mixins.formatJsDate_localTZ(toDate),
        type: state.activeTransTypes,
        ...(state.searchTerm && state.searchTerm.length > 0 && {keyword: state.searchTerm})
      })
      api.getAllLedgerTransactions(params)
        .then( response => {
          console.log("ledger transaction data loaded: ", response)
          commit('setLedgerTransactionData', response.data)
        })
        .catch (error => {
          console.log("failed to load ledger transaction data:", error)
          commit('setError', { 
            message: "Failed to load Ledger Transaction Data: ",
            reason: mixins.formatApiErrorMessage(error)
          })
        })
  },
  deleteTransaction( {commit}, transId) {
    return api.deleteTransaction(transId)
      .then( response => {
        console.log('ledgerTransaction deleted: ', response)
        return response
      })
      .catch(error => {
        console.log('failed to delete ledger transaction: ', error)
        commit('setError', {
          message: "Failed to delete ledger transaction: ",
          reason: mixins.formatApiErrorMessage(error)
        })
      })
  },
  updateTransaction( {commit}, payload) {
    return api.updateTransaction(payload.transId, payload.params)
      .then( response => {
        console.log('ledgerTransaction updated: ', response)
        return response
      })
      .catch(error => {
        console.log('failed to update ledger transaction: ', error)
        commit('setError', {
          message: "Failed to update ledger transaction: ",
          reason: mixins.formatApiErrorMessage(error)
        })
      })
  },
  setSelectedDateRange({ commit, dispatch }, range) {
    commit('setSelectedDateRange', range)
    dispatch('loadTransactions')
  },
  setActiveTransTypes({ commit, dispatch}, types) {
    commit('setActiveTransTypes', types)
    dispatch('loadTransactions')
  },
  setSearchTerm({ commit, dispatch }, term) {
    commit('setSearchTerm', term)
    dispatch('loadTransactions')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}